import { WebPageJsonLd } from 'next-seo';
import { controller } from 'src/switch-themes';
import { NEXT_DAILYMOTION_ID } from 'src/constants/index';
// type Props = {
//   meta?: IMeta;
// };

const { description, name } = controller;

export const HeadVideoPage = () => {
  return <>
    <script src={`${NEXT_DAILYMOTION_ID}`} />
  </>;
};

export const BodyVideoPage = () => {
  return (
    <>
      <WebPageJsonLd description={description} id={name.en} />
    </>
  );
};
