import { APP_IMG, APP_URL, WEB_NAME } from 'src/constants/index';
import { IArticle, IMeta } from 'src/interface/section';
import _ from 'lodash';
import { useMemo } from 'react';
import { controller } from 'src/switch-themes';
import { BreadcrumbJsonLd, NewsArticleJsonLd } from 'next-seo';
import { escapeChar } from 'src/utils/helper';

export interface IMapArticle extends IArticle {
  description: string;
  keyword: string;
  imageUrl: string;
}
type Props = {
  meta?: IMeta;
  article?: IArticle;
};

const { title, keyword, image } = controller;

export const HeadLotteryContent = () => {
  return null;
};

export const BodyLotteryContent = ({ article }: Props) => {
  if (_.isEmpty(article)) return null;
  const { _article, articleTitleSeo, lotterypath } = useMemo(() => {
    const _article = {
      ...article,
      blurb: article?.blub,
      title: article?.title || title,
      keyword: article?.meta?.keyword || keyword,
      imageUrl: article?.meta?.og_social_image || image.default
    };
    /* ----------------------------------- SEO ---------------------------------- */
    const articleTitleSeo: string = escapeChar(_article.title) || title;
    const lotterypath: string = `/lottery/${article?.dateDefault}`;

    return {
      _article,
      articleTitleSeo,
      lotterypath
    };
  }, [article]);

  return (
    <>
      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      <BreadcrumbJsonLd
        itemListElements={[
          { position: 1, name: 'หน้าแรก', item: `${APP_URL}` },
          {
            position: 2,
            name: `lottery`,
            item: `${APP_URL}/lottery`
          },
          {
            position: 3,
            name: `${articleTitleSeo}`,
            item: `${APP_URL}${lotterypath}`
          }
        ]}
      />

      {/* -------------------------- SNIPPET : NEWS_ARTICLE ------------------------- */}
      {/* <ArticleJsonLd
        url={`${APP_URL}${lotterypath}`}
        title={`${articleTitleSeo}`}
        images={[`${APP_IMG}${_article.image}`]}
        datePublished={`${_article.dateDefault}`}
        dateModified={`${_article.dateDefault}`}
        authorName={[
          {
            name: `${_article.byline || WEB_NAME}`,
            url: website
          }
        ]}
        publisherName={`${_article.byline || WEB_NAME}`}
        publisherLogo={`${image.logo}`}
        description={`${_article.blurb}`}
        isAccessibleForFree={true}
      /> */}

      {/* -------------------------- SNIPPET : NEWS_ARTICLE ------------------------- */}
      <NewsArticleJsonLd
        url={`${APP_URL}${lotterypath}`}
        title={`${articleTitleSeo}`}
        images={[`${APP_IMG}${_article.image}`]}
        section={`lottery`}
        keywords={`${_article.keyword}`}
        datePublished={`${_article.dateDefault}`}
        dateModified={`${_article.dateDefault}`}
        authorName={`${_article.byline || WEB_NAME}`}
        publisherName={`${_article.byline || WEB_NAME}`}
        description={`${_article.blurb}`}
        body={`${_article.blurb}`}
        isAccessibleForFree={true}
        dateCreated={`${_article.dateDefault}`}
        publisherLogo={`${image.logo}`}
      />
    </>
  );
};
