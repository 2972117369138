import getConfig from 'next/config';
import { IWebList } from 'src/interface/themes';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

/* ----------------------------- PUBLIC_RUNTIME ------------------------------ */
export const WEB_NAME: keyof IWebList = publicRuntimeConfig.WEB_NAME;

export const APP_ENV: any = publicRuntimeConfig.NEXT_PUBLIC_APP_ENV || 'production';
export const APP_URL: any = publicRuntimeConfig.NEXT_PUBLIC_APP_URL;
export const API_URI: any = publicRuntimeConfig.NEXT_PUBLIC_API_URL;
export const APP_IMG: any = publicRuntimeConfig.NEXT_PUBLIC_IMG_URL;

export const CLIENT_GSERVICE_URL = publicRuntimeConfig.NEXT_PUBLIC_GSERVICE_URL;

export const TRUE_HITS_URL: any = publicRuntimeConfig.NEXT_PUBLIC_TRUE_HITS_URL;
export const IZOOTO_URL: any = publicRuntimeConfig.NEXT_PUBLIC_IZOOTO_URL;

export const isProd: boolean = APP_ENV.toLowerCase() === 'production';
export const IS_NO_ROBOT_INDEX: boolean = !isProd;

export const CHECK_ADS: string = publicRuntimeConfig.CHECK_ADS;
export const DEFAULT_COUNT_VIEW: number = publicRuntimeConfig.DEFAULT_COUNT_VIEW;

export const NEXT_DAILYMOTION_ID = publicRuntimeConfig.NEXT_DAILYMOTION_ID;

export const NATION_TV_PUBLIC_PWA = publicRuntimeConfig.NATION_TV_PUBLIC_PWA;
// GTM
export const GTM_ID: any = publicRuntimeConfig.NEXT_PUBLIC_GTM_ID;
// RECAPTCHA
export const RECAPTCHA_SITE_KEY: any = publicRuntimeConfig.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '';

/* ----------------------------- SERVER_RUNTIME ------------------------------ */
// RECAPTCHA
export const RECAPTCHA_SECRET_KEY: any = serverRuntimeConfig.NEXT_SERVER_RECAPTCHA_SECRET_KEY || '';
// export const CONTENT_DEFAULT: any = serverRuntimeConfig.NEXT_SERVER_DEFAULT_ID || 0;

// REDIS
export const REDIS_URL: any = serverRuntimeConfig.NEXT_SERVER_REDIS_URL;
export const REDIS_PW = 'Nation#2021';
export const REDIS_PORT = 6379;

export const SHOW_COMMEMORATE = publicRuntimeConfig.SHOW_COMMEMORATE || process.env.SHOW_COMMEMORATE;

export const CONTENTS_INSTAGRAM = publicRuntimeConfig.CONTENTS_INSTAGRAM;
export const CONTENTS_TIKTOK = publicRuntimeConfig.CONTENTS_TIKTOK;
export const CONTENTS_TWITTER = publicRuntimeConfig.CONTENTS_TWITTER;
export const DAILYMOTION_HTML = publicRuntimeConfig.DAILYMOTION_HTML;

export const EMBED_ELECTION = publicRuntimeConfig.EMBED_ELECTION || process.env.EMBED_ELECTION;

// speech
export const NEXT_PUBLIC_URL_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_SOUND;
export const NEXT_PUBLIC_URL_PATH_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_PATH_SOUND;
export const NEXT_PUBLIC_WEB_NAME_SOUND = publicRuntimeConfig.NEXT_PUBLIC_WEB_NAME_SOUND;
export const NEXT_PUBLIC_ID_MOCK_SOUND = publicRuntimeConfig.NEXT_PUBLIC_ID_MOCK_SOUND;
export const NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND;
export const NEXT_PUBLIC_PLACEHOLDER_TIME_OUT = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TIME_OUT;
export const NEXT_PUBLIC_SPEED_KEYPOINT = publicRuntimeConfig.NEXT_PUBLIC_SPEED_KEYPOINT;

// AUTH0
export const AUTH0_BASE_URL = serverRuntimeConfig.AUTH0_BASE_URL;
export const AUTH0_ISSUER_BASE_URL = serverRuntimeConfig.AUTH0_ISSUER_BASE_URL;
export const AUTH0_CLIENT_ID = serverRuntimeConfig.AUTH0_CLIENT_ID;
export const AUTH0_CLIENT_SECRET = serverRuntimeConfig.AUTH0_CLIENT_SECRET;
export const REDIS_HOST = serverRuntimeConfig.REDIS_HOST;
export const REDIS_PASSWORD = serverRuntimeConfig.REDIS_PASSWORD;
export const REDIS_TTL = serverRuntimeConfig.REDIS_TTL || 86400;
export const NEXT_PUBLIC_LOGIN_COOKIE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_COOKIE_DATE || 1;
export const NEXT_PUBLIC_LOGIN_STATE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_STATE_DATE || 1;

export const NEXT_PUBLIC_TENCENT_CLOUD_SRC_URL = publicRuntimeConfig.NEXT_PUBLIC_TENCENT_CLOUD_SRC_URL;
export const NEXT_PUBLIC_TENCENT_CLOUD_DIV_ID = publicRuntimeConfig.NEXT_PUBLIC_TENCENT_CLOUD_DIV_ID;
export const NEXT_PUBLIC_TENCENT_CLOUD_M3U8_URL = publicRuntimeConfig.NEXT_PUBLIC_TENCENT_CLOUD_M3U8_URL;

export const NEXT_PUBLIC_TIMER_LANDING_PAGE = publicRuntimeConfig.NEXT_PUBLIC_TIMER_LANDING_PAGE;
