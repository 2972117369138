import { BreadcrumbJsonLd } from 'next-seo';

import { APP_URL } from 'src/constants/index';
import { IMeta } from 'src/interface/section';
import _ from 'lodash';

type Props = {
  meta?: IMeta;
};

export const HeadLotteryCategory = () => {
  return null;
};

export const BodyLotteryCategory = ({ meta }: Props) => {
  if (_.isEmpty(meta)) return null;

  return (
    <>
      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      <BreadcrumbJsonLd
        itemListElements={[
          { position: 1, name: 'หน้าแรก', item: `${APP_URL}` },
          {
            position: 2,
            name: 'สลากกินแบ่งรัฐบาล',
            item: `${APP_URL}/lottery`
          }
        ]}
      />
    </>
  );
};
