import { APP_IMG, APP_URL, CONTENTS_INSTAGRAM, CONTENTS_TIKTOK, CONTENTS_TWITTER, WEB_NAME } from 'src/constants';
import { IMeta } from 'src/interface/section';
import { escapeChar } from 'src/utils/helper';

const controller = {
  locale: 'th',
  name: { th: 'เนชั่นออนไลน์', en: 'เนชั่นทีวี' },
  trueHit: true,
  iZooTo: true,
  isEnglish: WEB_NAME === 'nationthailand',
  website: `${APP_URL}/`,
  image: {
    favicon: `${APP_IMG}/images/favicon.ico`,
    logo: `${APP_IMG}/images/logo.png`,
    logoFooter: `${APP_IMG}/images/logo-footer-v1.png`,
    logoHeader: `${APP_IMG}/images/logo-header-v1.png`,
    logoApple: `${APP_IMG}/images/logo-apple-touch.png`,
    logoSnop: `${APP_IMG}/images/logo_snop.png`,
    noArticle: `${APP_IMG}/images/no-article.jpg`,
    default: `${APP_IMG}/images/default.jpg`
  },
  title: 'เกาะติดสถานการณ์ข่าว ข่าวด่วน ข่าววันนี้ เนชั่นทีวี | เนชั่นออนไลน์',
  description: 'เนชั่นออนไลน์ เว็ปไซต์ข่าว ทันทุกสถานการณ์แบบนาทีต่อนาที เสนอครบทุกข่าว เจาะลึกวิเคราะห์ทุกประเด็น เพราะทุกสนามข่าวเราคือตัวจริง" | เนชั่นออนไลน์',
  keyword:
    'เนชั่นทีวี, สื่่อดิจิตอล, ข่าววันนี้, Nation, NationTV, ข่าวด่วน, ข่าวยอดนิยม, ข่าวออนไลน์, ข่าวล่าสุด, nationtv22, เนชั่นออนไลน์, NationOnline, Live, เจาะประเด็นร้อน, สถานการณ์รายวัน, ข่าวเนชั่น, เนชั่นทีวีช่อง22, ถ่ายทอดสด',
  theme: {
    title: 'default',
    appleBar: 'default',
    color: '#27398B'
  },
  facebook: {
    adminId: '',
    appId: '',
    pageId: '307513345840',
    pageEmbed: `<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FNationOnline&tabs=timeline&width=340&height=350&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="350" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`
  },
  twitter: {
    handle: 'NationTV22',
    site: '@NationTV22',
    creator: '@NationTV22',
    pageEmbed: ''
  },
  socials: {
    facebook: 'https://www.facebook.com/NationTV',
    // twitter: 'https://twitter.com/nationstoryTH',
    // instagram: 'https://www.instagram.com/nationstoryth/',
    youtube: 'https://www.youtube.com/@nationtvth',
    line: 'https://lin.ee/wI45KUT',
    tiktok: 'https://www.tiktok.com/@nationtvth'
  },
  address: {
    streetAddress: 'Nation multimedia group public company limited 1854 ชั้น 8 ถนนเทพรัตน แขวงบางนาใต้ เขตบางนา',
    addressLocality: 'กรุงเทพฯ',
    postalCode: '10260',
    addressRegion: 'TH',
    addressCountry: 'THAILAND'
  },
  homepage: {
    flipNewspaper: true
  },
  contents: {
    instagram: CONTENTS_INSTAGRAM,
    tikTok: CONTENTS_TIKTOK,
    twitter: CONTENTS_TWITTER,
    seoTitle: (meta: IMeta) => escapeChar(meta?.title) || '',
    seoDescription: (meta: IMeta) => escapeChar(meta?.description) || '',
    seoKeywords: (meta: IMeta) => meta?.keyword || ''
  },
  category: {
    pathUrl: (meta: IMeta) => (meta?.subCategory?.en ? `${APP_URL}/category/${meta?.category?.en}/${meta?.subCategory?.en}` : meta?.category?.en ? `${APP_URL}/category/${meta?.category?.en}` : null),
    seoTitle: (meta: IMeta) => (meta?.subCategory?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.subCategory.th}` : meta?.category?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.category.th}` : null),
    seoDescription: (meta: IMeta) => meta?.description || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  search: {
    seoTitle: (text: string) => `${text} รวมข่าวที่เกี่ยวกับ ${text}`,
    seoDescription: (text: string) => `ค้นหาข่าวเกี่ยวกับ ${text} เกาะติดข่าวของ ${text} วันนี้ ข่าวด่วน ${text} ที่คุณสนใจ ติดตามเรื่อง ${text}`,
    seoKeywords: (text: string) => `${text}, tags`
  },
  tags: {
    seoTitle: (text: string) => `${text} รวมข่าวที่เกี่ยวกับ ${text}`,
    seoDescription: (text: string) => `รวมข่าว ${text} เกาะติดข่าวของ ${text} ข่าวด่วนของ ${text} ที่คุณสนใจ ติดตามเรื่อง ${text}`,
    seoKeywords: (text: string) => `${text}, tags`
  },
  videoPage: {},
  videoContents: {
    instagram: CONTENTS_INSTAGRAM,
    tikTok: CONTENTS_TIKTOK,
    twitter: CONTENTS_TWITTER,
    seoTitle: (meta: IMeta) => escapeChar(meta?.title) || null,
    seoDescription: (meta: IMeta) => escapeChar(meta?.description) || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  videoCategory: {
    pathUrl: (meta: IMeta) => (meta?.subCategory?.en ? `${APP_URL}/category/${meta?.category?.en}/${meta?.subCategory?.en}` : meta?.category?.en ? `${APP_URL}/category/${meta?.category?.en}` : null),
    seoTitle: (meta: IMeta) => (meta?.subCategory?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.subCategory.th}` : meta?.category?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.category.th}` : null),
    seoDescription: (meta: IMeta) => meta?.description || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  videoTags: {
    seoTitle: (text: string) => `${text} รวมวิดีโอที่เกี่ยวกับ ${text}`,
    seoDescription: (text: string) => `รวมวิดีโอ ${text} เกาะติดข่าวของ ${text} ข่าวด่วนของ ${text} ที่คุณสนใจ ติดตามเรื่อง ${text}`,
    seoKeywords: (text: string) => `${text}, tags`
  },
  galleriesContents: {
    seoTitle: (meta: IMeta) => escapeChar(meta?.title) || null,
    seoDescription: (meta: IMeta) => escapeChar(meta?.description) || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  galleriesCategory: {
    pathUrl: (meta: IMeta) => (meta?.subCategory?.en ? `${APP_URL}/category/${meta?.category?.en}/${meta?.subCategory?.en}` : meta?.category?.en ? `${APP_URL}/category/${meta?.category?.en}` : null),
    seoTitle: (meta: IMeta) => (meta?.subCategory?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.subCategory.th}` : meta?.category?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.category.th}` : null),
    seoDescription: (meta: IMeta) => meta?.description || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  galleriesTags: {
    seoTitle: (text: string) => `${text} รวมแกลลอรี่ที่เกี่ยวกับ ${text}`,
    seoDescription: (text: string) => `รวมแกลลอรี่ ${text} เกาะติดข่าวของ ${text} ข่าวด่วนของ ${text} ที่คุณสนใจ ติดตามเรื่อง ${text}`,
    seoKeywords: (text: string) => `${text}, tags`
  },
  lotteryContents: {
    seoTitle: (meta: IMeta) => escapeChar(meta?.title) || null,
    seoDescription: (meta: IMeta) => escapeChar(meta?.description) || 'ผลสลากกินแบ่งรัฐบาลและลอตเตอรี่รวมผลสลากกินแบ่งรัฐบาล ตรวจงวดประจำวัน มีครบมากที่สุดต้อง | เนชั่นออนไลน์',
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  lotteryCategory: {
    pathUrl: (meta: IMeta) => (meta?.subCategory?.en ? `${APP_URL}/category/${meta?.category?.en}/${meta?.subCategory?.en}` : meta?.category?.en ? `${APP_URL}/category/${meta?.category?.en}` : null),
    seoTitle: (meta: IMeta) => (meta?.subCategory?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.subCategory.th}` : meta?.category?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.category.th}` : null),
    seoDescription: (meta: IMeta) => meta?.description || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  }
};

export default { ...controller };
