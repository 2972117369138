import { APP_URL } from 'src/constants/index';
import { IMeta } from 'src/interface/section';
import { controller } from 'src/switch-themes';
import { ProfilePageJsonLd, SiteLinksSearchBoxJsonLd } from 'next-seo';
import { useMemo } from 'react';
import { escapeChar } from 'src/utils/helper';

type Props = {
  meta?: IMeta;
  tagText?: string;
};

const { title, description, name } = controller;
export const HeadVideoTags = ({ meta, tagText }: Props) => {
  const { articleTitleSeo, articleDescriptionSeo } = useMemo(() => {
    const _article: IMeta = {
      ...meta,
      title: tagText ? tagText : title,
      description: tagText ? tagText : description
    };

    /* ----------------------------------- SEO ---------------------------------- */
    const articleTitleSeo: string = escapeChar(_article.title) || title;
    const articleDescriptionSeo: string = escapeChar(_article.description) || description;

    return {
      articleTitleSeo,
      articleDescriptionSeo
    };
  }, [meta, tagText]);

  return (
    <>
      {/* ---------------------------- SNIPPET : WEB_SITE --------------------------- */}
      {/* <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: `
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${WEB_NAME}",
              "url": "${APP_URL}",
              "description": "${articleDescriptionSeo}",
              "sameAs": [${WEB_URL_SAME_AS}],
              "potentialAction": {
                "@type": "SearchAction",
                "target": "${APP_URL}/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `
        }}
      /> */}
      {/* ---------------------------- SNIPPET : WEB_PAGE --------------------------- */}
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: `
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${articleTitleSeo}",
              "description": "${articleDescriptionSeo}",
              "publisher": {
                "@type": "ProfilePage",
                "name": "${name.en}"
              }
            }
          `
        }}
      />

      {/* ------------------------------- TRUE_HIT -------------------------------- */}
      {/* <script
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: `
            ${isEnglish ? '__en_page' : '__th_page'}="tags";
          `
        }}
      /> */}

      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      {/* <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: `
            { 
              "@context": "http://schema.org", 
              "@type": "BreadcrumbList", 
              "itemListElement": [ 
                { 
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "หน้าแรก", 
                  "item": "${APP_URL}" 
                }, 
                { 
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "${articleTitleSeo}", 
                  "item": "${APP_URL}/tags/${tagName}" 
                } 
              ] 
            } 
          `
        }}
      /> */}
    </>
  );
};

export const BodyVideoTags = ({ meta, tagText }: Props) => {
  const { _article, tagName } = useMemo(() => {
    const _article: IMeta = {
      ...meta,
      title: tagText ? tagText : title,
      keyword: `${tagText}, tags`
    };

    /* ----------------------------------- SEO ---------------------------------- */
    const tagName: string = escapeChar(tagText) || '';

    return {
      _article,
      tagName
    };
  }, [meta, tagText]);

  const textTitle = _article.title || title;

  return (
    <>
      {/* ---------------------------- SNIPPET : WEB_SITE --------------------------- */}
      <SiteLinksSearchBoxJsonLd
        url={`${APP_URL}`}
        potentialActions={[
          {
            target: `${APP_URL}/search?q`,
            queryInput: 'search_term_string'
          }
        ]}
      />

      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      <ProfilePageJsonLd
        breadcrumb={[
          { position: 1, name: 'หน้าแรก', item: `${APP_URL}` },
          {
            position: 2,
            name: `${textTitle}`,
            item: `${APP_URL}/tags/${tagName}`
          }
        ]}
      />
    </>
  );
};
