import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lg-video.scss';
import 'lightgallery/scss/lg-zoom.scss';
import 'lightgallery/scss/lightgallery.scss';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'src/assets/style/global.scss';
import 'src/assets/style/themes.scss';

import Script from 'next/script';
import { APP_IMG, TRUE_HITS_URL } from 'src/constants/index';
import { store } from 'src/redux/store';
import setStyleAttribute, { controller } from 'src/switch-themes';
import { splitRote } from 'src/utils/helper';
import { SnippetsPageBody } from 'src/utils/meta';

const PageError = dynamic(import('pages/404'), { ssr: true });
const SnippetsApp = dynamic(import('src/utils/meta/snippets/app'), { ssr: true });

function MyApp({ Component, pageProps: { ...pageProps } }: AppProps | any) {
  if (pageProps.statusCode >= 400) {
    return (
      <PageError sessionSevId={null} />
    );
  }
  const { trueHit } = controller;
  const router = useRouter();
  // only-themes
  useEffect(() => {
    setStyleAttribute();
  }, []);
  // only-themes
  const { facebook, twitter, theme, image, name } = controller;
  return (
    <CacheProvider value={cache}>
      <Head>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
      </Head>
      <ThemeProvider enableSystem={false}>
        <DefaultSeo
          additionalMetaTags={[
            {
              name: 'application-name',
              content: name.en
            },
            {
              name: 'theme-color',
              content: theme?.color || '#000000'
            },
            {
              name: 'mobile-web-app-capable',
              content: 'yes'
            },
            {
              name: 'msapplication-TileColor',
              content: '#000000'
            },
            {
              name: 'msapplication-TileImage',
              content: image.logo
            },
            {
              name: 'format-detection',
              content: `telephone=yes`
            },
            {
              httpEquiv: 'x-ua-compatible',
              content: 'IE=edge; chrome=1'
            },
            {
              name: 'google',
              content: 'nositelinkssearchbox'
            },
            // {/* ---------------------------------- APPLE_META --------------------------------- */}
            {
              name: 'apple-mobile-web-app-title',
              content: name.en
            },
            {
              name: 'apple-mobile-web-app-capable',
              content: 'yes'
            },
            {
              name: 'apple-mobile-web-app-status-bar-style',
              content: theme?.appleBar || 'default'
            },
            // {/* ---------------------------------- FACEBOOK --------------------------------- */}
            {
              property: 'fb:admins',
              content: facebook?.adminId
            },
            {
              property: 'fb:pages',
              content: facebook?.pageId
            }
          ]}
          facebook={{
            appId: facebook?.appId
          }}
          twitter={{
            handle: twitter?.creator,
            site: twitter?.site,
            cardType: 'summary_large_image'
          }}
          additionalLinkTags={[
            {
              rel: 'icon',
              href: image.logo
            },
            {
              rel: 'mask-icon',
              href: image.logo,
              color: '#000000'
            },
            {
              rel: 'shortcut icon',
              type: 'image/x-icon',
              href: image.favicon
            },
            // {/* ---------------------------------- APPLE_LINK + ICON --------------------------------- */}
            {
              rel: 'apple-touch-startup-image',
              href: image.logoApple
            },
            {
              rel: 'apple-touch-icon',
              href: `${APP_IMG}/images/Icon-58.png`,
              sizes: '58x58'
            },
            {
              rel: 'apple-touch-icon',
              href: `${APP_IMG}/images/Icon-80.png`,
              sizes: '80x80'
            },
            {
              rel: 'apple-touch-icon',
              href: `${APP_IMG}/images/Icon-152.png`,
              sizes: '152x152'
            },
            {
              rel: 'apple-touch-icon',
              href: `${APP_IMG}/images/Icon-167.png`,
              sizes: '167x167'
            },
            {
              rel: 'apple-touch-icon',
              href: `${APP_IMG}/images/Icon-180.png`,
              sizes: '180x180'
            },
            {
              rel: 'apple-touch-icon',
              href: `${APP_IMG}/images/Icon-192.png`,
              sizes: '192x192'
            },
            // --------------------------------- MANIFEST -----------------------------------
            {
              rel: 'manifest',
              href: `${splitRote(router)}/static/manifest.json`
            },
            // ---------------------------------- FONTS -------------------------------------
            {
              rel: 'preload',
              href: 'https://fonts.googleapis.com'
            },
            {
              rel: 'preload',
              href: 'https://fonts.gstatic.com',
              crossOrigin: 'true'
            }
          ]}
        />
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
        {/* ---------------------------- SNIPPET -------------------------- */}
        <SnippetsApp />
        <SnippetsPageBody data={pageProps} page={router.route} />

        {/* --------------------------------- Truehit -------------------------------- */}
        {trueHit && <Script strategy='afterInteractive' type='text/javascript' src={`${TRUE_HITS_URL}`} />}
      </ThemeProvider>
    </CacheProvider>
  );
}

export default MyApp;
