import { APP_IMG, APP_URL } from 'src/constants/index';
import { IArticle, IMeta } from 'src/interface/section';
import Script from 'next/script';
import _ from 'lodash';
import { useMemo } from 'react';
import { controller } from 'src/switch-themes';
import { BreadcrumbJsonLd, NewsArticleJsonLd } from 'next-seo';
import { NEXT_DAILYMOTION_ID } from 'src/constants';
export interface IMapArticle extends IArticle {
  description: string;
  keyword: string;
  imageUrl: string;
}
type Props = {
  meta?: IMeta;
  article?: IArticle | any;
};

// const { listShowAds } = ads;
const {
  title,
  description,
  keyword,
  image,
  name,
  isEnglish,
  contents: { instagram, twitter, tikTok }
} = controller;


export const HeadVideoContentGallery = () => {

  return (
    <>
      <script src={`${NEXT_DAILYMOTION_ID}`} />
    </>
  );
};

export const BodyVideoContentGallery = ({ article }: Props) => {
  if (_.isEmpty(article)) return null;

  const { _article, setItemListElement } = useMemo(() => {
    const _article: IMapArticle = {
      ...article,
      title: article?.title || title,
      byline: article?.byline || name.en,
      description: article?.blurb || description,
      keyword: article?.meta?.keyword || keyword,
      imageUrl: article?.meta?.og_social_image || image.default
    };
    /* ----------------------------------- SEO ---------------------------------- */
    // const articleTitleSeo: string = _article.title || title;
    // const articleDescriptionSeo: string = seoDescription(_article.description) || description;

    /* ------------------------------- Breadcrumb ------------------------------- */
    let setItemListElement: any = '';

    if (!_.isEmpty(article?.subCategory?.en)) {
      setItemListElement = [
        {
          position: 1,
          name: isEnglish ? 'Home' : 'หน้าแรก',
          item: `${APP_URL}`
        },
        {
          position: 2,
          name: `${_article?.section?.th}`,
          item: `${APP_URL}/${_article?.section?.en}`
        },
        {
          position: 3,
          name: `${_article?.subCategory?.th}`,
          item: `${APP_URL}/${_article?.section?.en}/${_article?.subCategory?.en}`
        },
        {
          position: 4,
          name: `${_article.title}`,
          item: `${_article?.canonical}` || !_.isEmpty(_article?.link) && `${APP_URL}${_article?.link}` || `${APP_URL}/${_article?.section?.en}/${_article?.subCategory?.en}${_article?.link}`
        }
      ];
    } else {
      setItemListElement = [
        {
          position: 1,
          name: isEnglish ? 'Home' : 'หน้าแรก',
          item: `${APP_URL}`
        },
        {
          position: 2,
          name: `${_article?.section?.th}`,
          item: `${APP_URL}/${_article?.section?.en}`
        },
        {
          position: 3,
          name: `${_article.title}`,
          item: `${_article?.canonical}` || !_.isEmpty(_article?.link) && `${APP_URL}${_article?.link}` || `${APP_URL}/${_article?.section?.en}${_article?.link}`
        }
      ];
    }

    return {
      _article,
      setItemListElement
    };
  }, [article]);

  const textTitle = _article.title || title;
  const textDescription = _article.description || description;

  return (
    <>
      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      <BreadcrumbJsonLd itemListElements={setItemListElement} />

      {/* -------------------------- SNIPPET : NEWS_ARTICLE ------------------------- */}
      {/* <ArticleJsonLd
        url={`${_article?.canonical}` || `${APP_URL}${_article.link}`}
        title={`${textTitle}`}
        images={[`${APP_IMG}${_article.image}`]}
        datePublished={`${_article.published_at}`}
        dateModified={`${_article.published_at}`}
        authorName={[
          {
            name: `${_article.byline || name.en}`,
            url: website
          }
        ]}
        publisherName={`${_article.byline || name.en}`}
        publisherLogo={`${image.logo}`}
        description={textDescription}
        isAccessibleForFree={true}
      /> */}

      <NewsArticleJsonLd
        url={`${_article?.canonical}` || `${APP_URL}${_article.link}`}
        title={`${textTitle}`}
        images={[`${APP_IMG}${_article.image}`]}
        section={`${_article.section?.th}`}
        keywords={`${_article.keyword}`}
        datePublished={`${_article.published_at}`}
        dateModified={`${_article.published_at}`}
        authorName={`${_article.byline}`}
        publisherName={`${_article.byline}`}
        description={`${textDescription}`}
        body={`${textDescription}`}
        dateCreated={`${_article.published_at}`}
        publisherLogo={`${image.logo}`}
        isAccessibleForFree={true}
      />
      {/* --------------------------- SOCIAL EMBED SCRIPT -------------------------- */}
      {instagram === 'true' && <Script id='widget-instagram' strategy='afterInteractive' async src='//www.instagram.com/embed.js' />}
      {twitter === 'true' && <Script id='widget-twitter' strategy='afterInteractive' async src='https://platform.twitter.com/widgets.js' />}
      {tikTok === 'true' && <Script id='widget-tik-tok' strategy='afterInteractive' async src='https://www.tiktok.com/embed.js' />}
    </>
  );
};
