import { useMemo } from 'react';
import { BreadcrumbJsonLd } from 'next-seo';

import { controller } from 'src/switch-themes';
import { APP_URL } from 'src/constants/index';
import { IMeta } from 'src/interface/section';
import _ from 'lodash';

type Props = {
  meta?: IMeta;
};

const { isEnglish } = controller;

export const HeadGalleriesCategory = () => {
  return null;
};

export const BodyGalleriesCategory = ({ meta }: Props) => {
  if (_.isEmpty(meta)) return null;
  const typePage = 'category';
  const { setItemListElement } = useMemo(() => {
    /* ------------------------------- Breadcrumb ------------------------------- */
    let setItemListElement: any = '';

    if (!_.isEmpty(meta?.subCategory?.en)) {
      setItemListElement = [
        {
          position: 1,
          name: isEnglish ? 'Home' : 'หน้าแรก',
          item: `${APP_URL}`
        },
        {
          position: 2,
          name: `${meta?.category?.th}`,
          item: `${APP_URL}/${typePage}/${meta?.category?.en}`
        },
        {
          position: 3,
          name: `${meta?.subCategory?.th}`,
          item: `${APP_URL}/${typePage}/${meta?.category?.en}/${meta?.subCategory?.en}`
        }
      ];
    } else {
      setItemListElement = [
        {
          position: 1,
          name: isEnglish ? 'Home' : 'หน้าแรก',
          item: `${APP_URL}`
        },
        {
          position: 2,
          name: `${meta.category?.th}`,
          item: `${APP_URL}/${typePage}/${meta.category?.en}`
        }
      ];
    }

    return {
      setItemListElement
    };
  }, [meta]);

  return (
    <>
      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      <BreadcrumbJsonLd itemListElements={setItemListElement} />
    </>
  );
};
