import nationtvAds from 'src/switch-themes/nationtv/ads';
import nationtvColor from 'src/switch-themes/nationtv/colors';
import nationtvFonts from 'src/switch-themes/nationtv/fonts';
import nationtvImages from 'src/switch-themes/nationtv/images';
import nationtvController from 'src/switch-themes/nationtv/controller';
import { WEB_NAME } from 'src/constants';
import { isEmpty } from 'lodash';
export interface ITheme {
  color: {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    text: string;
    lottery: string;
    newsPaper: string;
    newsPaperHover: string;
    shadow: string;
  };
  front: {
    primary: string;
    thin: string;
    secondary: string;
  };
  gradient: {
    primary: string;
    secondary: string;
    border: string;
  };
}

const webList: any = {
  nationtv: {
    ads: { ...nationtvAds },
    colors: { ...nationtvColor },
    fonts: [...nationtvFonts],
    images: { ...nationtvImages },
    controller: { ...nationtvController }
  }
};

const themeDefault = !isEmpty(webList[WEB_NAME]) ? webList[WEB_NAME] : webList['nationtv'];
export const {
  ads,
  colors: { color, gradient, front },
  fonts,
  images,
  controller
} = themeDefault;

const setStyleAttribute = () => {
  document.documentElement.style.setProperty('--primary-color', color.primary);
  document.documentElement.style.setProperty('--secondary-color', color.secondary);
  document.documentElement.style.setProperty('--tertiary-color', color.tertiary);
  document.documentElement.style.setProperty('--quaternary-color', color.quaternary);
  document.documentElement.style.setProperty('--front-primary', front.primary);
  document.documentElement.style.setProperty('--front-thin', front.thin);
  document.documentElement.style.setProperty('--front-secondary', front.secondary);
  document.documentElement.style.setProperty('--text-color', color.text);
  document.documentElement.style.setProperty('--shadow-color', color.shadow);
  document.documentElement.style.setProperty('--primary-gradient', gradient.primary);
  document.documentElement.style.setProperty('--secondary-gradient', gradient.secondary);
  document.documentElement.style.setProperty('--border-gradient', gradient.border);
  document.documentElement.style.setProperty('--lottery', color.lottery);
  document.documentElement.style.setProperty('--newspaper', color.newsPaper);
  document.documentElement.style.setProperty('--newspaper-hover', color.newsPaperHover);
};

export default setStyleAttribute;
