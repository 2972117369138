import { convertPage } from 'src/utils/helper';
import { BodyHomepage, HeadHomepage } from 'src/utils/meta/snippets/homepage';
import { BodyArticlesContent, HeadArticlesContent } from 'src/utils/meta/snippets/articles/content';
import { BodyArticlesCategory, HeadArticlesCategory } from 'src/utils/meta/snippets/articles/category';
import { BodyArticlesTags, HeadArticlesTags } from 'src/utils/meta/snippets/articles/tags';
import { SiteLinksSearchBoxJsonLd } from 'next-seo';
import { APP_URL } from 'src/constants';
import { BodyGalleriesCategory, HeadGalleriesCategory } from 'src/utils/meta/snippets/galleries/category';
import { BodyGalleriesContent, HeadGalleriesContent } from 'src/utils/meta/snippets/galleries/content';
import { BodyGalleriesTags, HeadGalleriesTags } from 'src/utils/meta/snippets/galleries/tags';
import { BodyVideoPage, HeadVideoPage } from 'src/utils/meta/snippets/videos';
import { BodyVideoPageGallery, HeadVideoPageGallery } from 'src/utils/meta/snippets/videos-galleries';
import { BodyVideoCategory, HeadVideoCategory } from 'src/utils/meta/snippets/videos/category';
import { BodyVideoContent, HeadVideoContent } from 'src/utils/meta/snippets/videos/content';
import { BodyVideoContentGallery, HeadVideoContentGallery } from 'src/utils/meta/snippets/videos-galleries/content';
import { BodyVideoTags, HeadVideoTags } from 'src/utils/meta/snippets/videos/tags';
import { BodyLotteryCategory, HeadLotteryCategory } from './snippets/lottery/category';
import { BodyLotteryContent, HeadLotteryContent } from './snippets/lottery/content';

type Props = {
  data?: any;
  page?: string;
};

export const snippets: any = {
  homepage: {
    header: (props?: any) => <HeadHomepage {...props} />,
    body: (props?: any) => <BodyHomepage {...props} />
  },
  category: {
    header: (props?: any) => <HeadArticlesCategory {...props} />,
    body: (props?: any) => <BodyArticlesCategory {...props} />
  },
  contents: {
    header: (props?: any) => <HeadArticlesContent {...props} />,
    body: (props?: any) => <BodyArticlesContent {...props} />
  },
  tags: {
    header: (props?: any) => <HeadArticlesTags {...props} />,
    body: (props?: any) => <BodyArticlesTags {...props} />
  },
  videoPage: {
    header: (props?: any) => <HeadVideoPage {...props} />,
    body: (props?: any) => <BodyVideoPage {...props} />
  },
  videoPageGallery: {
    header: (props?: any) => <HeadVideoPageGallery {...props} />,
    body: (props?: any) => <BodyVideoPageGallery {...props} />
  },
  videoCategory: {
    header: (props?: any) => <HeadVideoCategory {...props} />,
    body: (props?: any) => <BodyVideoCategory {...props} />
  },
  videoContents: {
    header: (props?: any) => <HeadVideoContent {...props} />,
    body: (props?: any) => <BodyVideoContent {...props} />
  },
  videoContentsGallery: {
    header: (props?: any) => <HeadVideoContentGallery {...props} />,
    body: (props?: any) => <BodyVideoContentGallery {...props} />
  },
  videoTags: {
    header: (props?: any) => <HeadVideoTags {...props} />,
    body: (props?: any) => <BodyVideoTags {...props} />
  },
  galleriesCategory: {
    header: (props?: any) => <HeadGalleriesCategory {...props} />,
    body: (props?: any) => <BodyGalleriesCategory {...props} />
  },
  galleriesContents: {
    header: (props?: any) => <HeadGalleriesContent {...props} />,
    body: (props?: any) => <BodyGalleriesContent {...props} />
  },
  galleriesTags: {
    header: (props?: any) => <HeadGalleriesTags {...props} />,
    body: (props?: any) => <BodyGalleriesTags {...props} />
  },
  lotteryCategory: {
    header: (props?: any) => <HeadLotteryCategory {...props} />,
    body: (props?: any) => <BodyLotteryCategory {...props} />
  },
  lotteryContents: {
    header: (props?: any) => <HeadLotteryContent {...props} />,
    body: (props?: any) => <BodyLotteryContent {...props} />
  }
};

export const SnippetsPageBody = ({ data, page }: Props) => {
  const _page = convertPage(page);
  if (snippets[_page])
    return (
      <>
        <SiteLinksSearchBoxJsonLd
          url={`${APP_URL}`}
          potentialActions={[
            {
              target: `${APP_URL}/search?q`,
              queryInput: 'search_term_string'
            }
          ]}
        />
        {snippets[_page].body(data)}
      </>
    );
  else return null;
};

// ! <meta/> ห้าม return null
const MetePage = ({ data, page }: Props) => {
  const _page = convertPage(page);
  if (snippets[_page]) return snippets[_page].header(data);
  else return '';
};

export default MetePage;
