import { ITheme } from 'src/switch-themes';

const nationtvColor: ITheme = {
  color: {
    primary: '#194588',
    secondary: '#6CA3D3',
    tertiary: '#0d6efd',
    quaternary: '#3b6bb5',
    text: '#fff',
    lottery: '#121a6b',
    newsPaper: '#0111c2',
    newsPaperHover: 'rgba(1, 17, 194, 0.5)',
    shadow: 'rgba(1, 17, 194, 0.3)'
  },
  front: {
    primary: 'GraphikThBold',
    thin: 'GraphikTh',
    secondary: 'Sarabun'
  },
  gradient: {
    primary: 'linear-gradient(270deg, #4377CA 0%, #1E3256 100%)',
    secondary: 'linear-gradient(270deg, #4377ca 0%, #1e3256 100%)',
    border: 'linear-gradient(90deg, #6ba3d2 1.56%, #6ba3d2 26.17%, #4377ca 26.17%, #4377ca 50.77%, #194588 50.78%, #194588 75.38%, #1e3256 75.39%, #1e3256 100%)'
  }
};
export default nationtvColor;
