import { APP_IMG } from 'src/constants';

type TImages = {
  headerLogo: string;
  footerLogo: string;
};

const images: TImages = {
  headerLogo: `${APP_IMG}/logo.png`,
  footerLogo: `${APP_IMG}/logo.png`
};

export default { ...images };
